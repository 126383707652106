import Link from "next/link";
import { useAuth } from "../auth";
import NavLanding from "../components/NavLanding";

function Home(): React.ReactElement {
  const { isAuthenticated, loading } = useAuth();

  return (
    <div>
      <NavLanding authLoading={loading} authenticated={isAuthenticated} />
      <h2 className="text-xl mb-3">Welcome to margins.online!</h2>
      <p className="landing-copy">Your commonplace book for the web.</p>
      <p className="landing-copy">
        We are in private beta. Interested?{" "}
        <a className="landing-link" href="https://airtable.com/shrD0c7NNzVEebEel">
          Join the waiting list.
        </a>
      </p>
      <p className="landing-copy">
        Want to know more? Read about our{" "}
        <Link href="/purpose">
          <a className="landing-link">/purpose</a>
        </Link>
      </p>
    </div>
  );
}

export default Home;
