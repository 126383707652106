import React from "react";
import Link from "next/link";

interface Props {
  authLoading: boolean;
  authenticated: boolean;
}

const Nav: React.FC<Props> = ({ authLoading, authenticated }: Props): React.ReactElement => {
  return (
    <nav className="flex items-center justify-between py-4">
      <div>
        <Link href="/notes">
          <a className="text-gray-800 hover:text-gray-900 py-2 px-4 border-2 border-gray-800 hover:border-gray-900 rounded">
            Margins
          </a>
        </Link>
      </div>
      <div className="flex items-center">
        {authLoading || !authenticated ? (
          <Link href="/login">
            <a className="inline-block ml-1 fill-current text-gray-600 hover:text-gray-900 py-1 px-3 border border-gray-400 hover:border-gray-900 rounded">
              <span>Login</span>
            </a>
          </Link>
        ) : (
          <Link href="/notes">
            <a className="inline-block ml-1 fill-current text-gray-600 hover:text-gray-900 py-1 px-3 border border-gray-400 hover:border-gray-900 rounded">
              <span>To My Notes</span>
            </a>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Nav;
